<template>
  <div>
    <a-layout v-if="isLogin">
      <a-layout-sider class="sidebar" width="256" v-model="collapsed" :trigger="null" collapsible>
        <sidebar :collapsed="collapsed" />
      </a-layout-sider>

      <a-layout>
        <a-layout-header class="headbar">
          <headbar :collapsed="collapsed" :username="username" @toggleCollapsed="toggleCollapsed" />
        </a-layout-header>

        <a-layout-content>
          <tab-layout />
          <router-view v-if="havePermisssion" style="padding: 8px" />
          <a-result v-else status="403" title="403" :sub-title="$t('抱歉, 您无权访问此页面')" style="margin-top: 36px" />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { getInfo } from "@/api/user";
import Cookies from "js-cookie";
import moment from "moment";
import { inventoryList, batchList } from "@/api/material";
import { getLogoConfigs } from "@/api/system";

export default {
  name: "BaseLayout",
  components: {
    Headbar: () => import("@/components/Headbar/Headbar"),
    Sidebar: () => import("@/components/Sidebar/Sidebar"),
    TabLayout: () => import("@/components/TabLayout/TabLayout"),
  },
  provide() {
    return {
      reloadPage: () => {
        return this.reloadPage();
      },
    };
  },
  data() {
    return {
      isLogin: false,
      collapsed: false,
      isRouterAlive: true,
    };
  },
  computed: {
    username() {
      return this.$store.state.user.username;
    },
    isManager() {
      return this.$store.state.user.isManager;
    },
    permissions() {
      return this.$store.state.user.permissions;
    },
    havePermisssion() {
      if (this.$route.meta.permission) {
        const permission = this.$route.meta.permission;
        return this.isManager || this.permissions.indexOf(permission) !== -1;
      } else {
        return true;
      }
    },
  },
  methods: {
    initialize() {
      if (!Cookies.get("access") || !Cookies.get("refresh")) {
        return this.$router.push("/user/login");
      }

      getInfo().then((data) => {
        this.isLogin = true;
        this.$store.commit("setUser", data);
        const currentTime = moment();
        const expiryTime = moment(data.expiry_time);
        const diffDays = expiryTime.diff(currentTime, "day");

        if (diffDays < 30) {
          this.$notification.warning({
            message: this.$t("到期预警"),
            description: this.$t("剩余日期") + `: ${diffDays} ` + this.$t("天"),
            duration: null,
          });
        }
      });

      // 库存预警
      inventoryList({ page: 1, is_inventory_warning: true }).then((data) => {
        if (data.count > 0) {
          this.$notification.warning({
            message: this.$t("库存预警"),
            description: this.$t("已有") + `${data.count} ` + this.$t("条库存预警信息"),
          });
        }
      });

      // 临期预警
      batchList({ page: 1, page_size: 16, has_stock: true, is_expiration_warning: true }).then((data) => {
        if (data.count > 0) {
          this.$notification.warning({
            message: this.$t("临期预警"),
            description: this.$t("已有") + `${data.count} ` + this.$t("条临期预警信息"),
          });
        }
      });
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    reloadPage() {
      this.isRouterAlive = false;
      this.$nextTick(() => (this.isRouterAlive = true));
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
.headbar {
  background: #fff;
  padding: 0;
  box-shadow: 0px 1px 10px #7774;
  border-bottom: 1px solid #6662 !important;
}

.sidebar {
  background: #fff;
  overflow: auto;
  box-shadow: 1px 0px 10px #7774;
  z-index: 9;
}
</style>
